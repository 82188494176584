
























































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import { namespace } from "vuex-class";
import FscCard from "@/components/Card/FscCard.vue";
import Table from "@/components/Table.vue";
import Actions from "@/components/Actions.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import AccessTypeButton from "@/components/Button/AccessTypeButton.vue";
import ProductCreate from "@/views/Product/ProductCreate.vue";
import { IProduct } from "@/interfaces/IProduct";
import ProductUpdate from "@/views/Product/ProductUpdate.vue";
import ProductInfo from "@/views/Product/ProductInfo.vue";
import { LocaleMessage } from "vue-i18n";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import ProductsFilterModal from "@/views/Product/ProductsFilterModal.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import CompanyMixin from "@/mixins/CompanyMixin";
import FscModal from "@/components/Modal/FscModal.vue";
import ModalMixin from "@/mixins/ModalMixin";

const ProductModule = namespace("product");
const StandardProductModule = namespace("standard-product");

@Component({
  components: {
    FscModal,
    ProductsFilterModal,
    FscPageHeader,
    ArchiveButton,
    ProductInfo,
    ProductUpdate,
    ProductCreate,
    AccessTypeButton,
    Table,
    FscCard,
    Actions,
    RemoveButton,
    EditButton,
    CreateButton,
  },
})
export default class Products extends mixins(ViewSwitchMixin, CompanyMixin, ModalMixin) {
  public name = "Products";

  public fields = [
    {
      key: "name",
      label: this.$t("product.name"),
      sortable: true,
    },
    {
      key: "standardProduct",
      label: this.$t("product.assignment"),
      sortable: true,
    },
  ];
  private searchTerm = "";
  protected productsModalFilterId = "productsModalFilterId";
  protected filterActive = false;
  protected archived = false;
  protected standardProductIds: null | Array<number> = null;

  get showProductCreate() {
    return this.visibleForm && this.typeCreate();
  }

  get showProductEdit() {
    return this.visibleForm && this.typeEdit() && this.selectedRow && !this.selectedRow.archived;
  }

  get showProductInfo() {
    return this.visibleForm && this.typeEdit() && this.selectedRow && this.selectedRow.archived;
  }

  @ProductModule.Action("filter")
  public filterAction: any;

  @ProductModule.Action("archive")
  public archiveAction: any;

  @ProductModule.Getter("getDataList")
  public products: any;

  @ProductModule.Getter("getTotal")
  public totalRows: any;

  @ProductModule.Getter("getArchiveSuccess")
  public archiveSuccess: any;

  @ProductModule.Getter("getIsLoading")
  public isLoading: any;

  @StandardProductModule.Action("findAll")
  public findAllStandardProducts!: (options: ICrudOptions) => Promise<void>;

  @StandardProductModule.Getter("getDataList")
  private standardProducts!: Array<IBasicNamedDTO>;

  private standartProductsOptions: any = [];

  private addOn: any = {
    id: -1,
    name: "Addon",
  };

  private onProductRowClick(item: IProduct, index: number, $event: any): void {
    this.selectedRow = item;
  }

  private onProductRowDblClick(item: IProduct, index: number, $event: any): void {
    this.selectedRow = item;
    this.openProductEditForm();
  }

  private openProductEditForm(): void {
    this.closeForm();
    this.onEdit();
  }

  private onProductChange(): void {
    this.closeForm();
    this.reload();
  }

  private renderAssignedProduct(product: IProduct): string | LocaleMessage {
    if (product.addon) {
      return this.$t("price_list.addon");
    }
    if (product.standardProduct && product.standardProduct.name) {
      return product.standardProduct.name;
    }
    return this.$t("general.no_assignement");
  }

  private async archiveProduct(): Promise<void> {
    if (!this.selectedRow) {
      return;
    }
    await this.archiveAction({
      resource: "products",
      params: { archived: !this.archived, id: this.selectedRow.id },
    });
    this.closeForm();
    this.hideArchiveModal();

    if (this.archiveSuccess) {
      this.reload();
    }
  }

  private showArchiveModal(): void {
    if (!this.selectedRow) {
      return;
    }
    this.showModal("product-archive-modal");
  }

  private hideArchiveModal(): void {
    this.hideModal("product-archive-modal");
  }

  public requestQuery(query: Record<any, any>): void {
    this.searchTerm = query.search;
    this.reload();
  }

  private reload(): void {
    this.filterAction({
      resource: "products",
      filter: {
        archived: this.archived,
        searchTerm: this.searchTerm,
        standardProductIds: this.standardProductIds,
      },
    });
  }

  protected onFilter(filter: { archive: boolean; standardProductIds: Array<number> | null }): void {
    this.archived = filter.archive;
    this.standardProductIds = filter.standardProductIds;
    this.reload();
  }

  protected onFilterActive(value: boolean): void {
    this.filterActive = value;
  }

  protected async filterClicked(): Promise<any> {
    await this.findAllStandardProducts({
      resource: "standard-products",
    });
    if (this.standardProducts) {
      this.standartProductsOptions = [...this.standardProducts];
      this.standartProductsOptions.push(this.addOn);
    }
    this.$bvModal.show(this.productsModalFilterId);
  }

  // protected async mounted(): Promise<void> {
  // await this.findAllStandardProducts({
  //   resource: "standard-products",
  // });
  // if (this.standardProducts) {
  //   this.standartProductsOptions = [...this.standardProducts];
  //   this.standartProductsOptions.push(this.addOn);
  // }
  // }

  private getQuestion(): string {
    if (this.archived) {
      return `${this.$t("question.unarchive", {
        item: '"' + this.selectedRow.name + '"',
      })}`;
    } else {
      return `${this.$t("question.archive", {
        item: '"' + this.selectedRow.name + '"',
      })}`;
    }
  }
}
