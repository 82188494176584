




import { Component, Vue, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import FscInput from "@/components/Field/FscInput.vue";
import { namespace } from "vuex-class";
import Validation from "@/components/Validation.vue";
import Table from "@/components/Table.vue";
import ProductForm from "@/views/Product/ProductForm.vue";
import { IProduct } from "@/interfaces/IProduct";

const ProductModule = namespace("product");
@Component({
  components: {
    ProductForm,
    Table,
    Validation,
    FscInput,
    FscCard,
  },
})
export default class ProductCreate extends Vue {
  public name = "ProductCreate";

  private product?: IProduct | null = null;

  @ProductModule.Action("create")
  public create: any;

  @ProductModule.Getter("getSuccess")
  public success: any;

  @ProductModule.Getter("getIsLoading")
  public getIsLoading: any;

  @Watch("success")
  private onSuccess(success: any): void {
    if (success) {
      this.$emit("close");
    }
  }

  public onSave(data: any): void {
    this.create({
      data: data,
      resource: "products",
      descriptionField: "name",
    });
  }
}
