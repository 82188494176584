




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import FscInput from "@/components/Field/FscInput.vue";
import { namespace } from "vuex-class";
import Validation from "@/components/Validation.vue";
import Table from "@/components/Table.vue";
import ProductForm from "@/views/Product/ProductForm.vue";
import { IProduct } from "@/interfaces/IProduct";

const ProductModule = namespace("product");
@Component({
  components: {
    ProductForm,
    Table,
    Validation,
    FscInput,
    FscCard,
  },
})
export default class ProductUpdate extends Vue {
  public name = "ProductUpdate";

  @Prop()
  public productId!: number;

  @ProductModule.Action("update")
  public update: any;

  @ProductModule.Action("findOne")
  public findOne: any;

  @ProductModule.Getter("getDataItem")
  public product: any;

  @ProductModule.Getter("getSuccess")
  public success: any;

  @ProductModule.Getter("getIsLoading")
  public isLoading: any;

  public onSave(data: IProduct): void {
    this.update({
      id: this.productId,
      data: { id: this.productId, ...data },
      resource: "products",
      descriptionField: "name",
    });
  }

  @Watch("productId")
  private onProductIdChange(productId: number): void {
    if (productId) {
      this.findOne({
        id: productId,
        resource: "products",
        descriptionField: "name",
      });
    }
  }

  @Watch("success")
  private onSuccess(success: any): void {
    if (success) {
      this.$emit("close");
    }
  }

  public mounted(): void {
    if (this.productId) {
      this.findOne({
        id: this.productId,
        resource: "products",
        descriptionField: "name",
      });
    }
  }
}
