





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import FscInput from "@/components/Field/FscInput.vue";
import { namespace } from "vuex-class";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import Table from "@/components/Table.vue";
import FscTable from "@/components/Table/FscTable.vue";
import { ILicenseClassProduct } from "@/interfaces/ILicenseClassProduct";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import { removeObject } from "@/utils/ArrayUtils";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IProduct } from "@/interfaces/IProduct";

const ProductModule = namespace("product");
const LicenseClassModule = namespace("license-class");
const VatModule = namespace("vat");
@Component({
  components: {
    SaveButton,
    AbortButton,
    RemoveButton,
    FscTable,
    Table,
    Validation,
    FscInput,
    FscCard,
  },
})
export default class ProductInfo extends Vue {
  public name = "ProductInfo";

  private licenseClassProductsFields = [
    {
      key: "licenseClass",
      label: this.$tc("general.license_class"),
      sortable: false,
    },
    {
      key: "vat",
      label: this.$t("general.vat"),
      sortable: false,
    },
    {
      key: "bookAtStudentRegistration",
      label: this.$t("general.book_goods"),
      sortable: false,
    },
  ];

  @Validate({ required })
  public productName = "";
  @Validate({ required })
  public licenseClasses: Array<string> = [];

  @Validate({ required })
  public standardProduct: IProduct | null = null;

  public licenseClassProducts: Array<ILicenseClassProduct> = [];

  @ProductModule.Action("findOne")
  public findOne: any;

  @LicenseClassModule.Action("filter")
  public filterLicenseClassesAction: any;

  @VatModule.Action("findAll")
  public findAllVatAction: any;

  @LicenseClassModule.Getter("getDataList")
  public licenseClassOptions: any;

  @ProductModule.Getter("getDataList")
  public products: any;

  @VatModule.Getter("getDataList")
  public vats: any;

  @Prop()
  public product!: IProduct;

  @Watch("product")
  public initData(product: IProduct): void {
    if (product) {
      this.productName = product.name ? product.name : "";
      this.licenseClassProducts = product.licenseClassProducts ? product.licenseClassProducts : [];
      this.standardProduct = product.standardProduct ? product.standardProduct : null;
    }
    if (product.licenseClassProducts && this.licenseClassOptions) {
      this.licenseClasses = product.licenseClassProducts.map((lc: any) => lc.licenseClass);
    }
  }

  get standardProducts() {
    if (this.products) {
      let products = this.products.filter((product: any) => product.standardProduct);
      products.push({ id: -1, name: "Other" });
      return products;
    }
    return [];
  }

  private licenseClassesSelectHandler($event: any): void {
    const licenseClassIndex = this.licenseClassProducts.findIndex((lc: any) => lc.licenseClass === $event);
    if (licenseClassIndex === -1) {
      const vat = this.vats.length ? this.vats[0] : {};
      const newLicenseClass: ILicenseClassProduct = {
        productId: this.product.id,
        licenseClass: $event,
        bookAtStudentRegistration: false,
        vat: vat,
      };
      this.licenseClassProducts.push(newLicenseClass);
    }
  }

  private licenseClassesRemoveHandler($event: any): void {
    const licenseClassProductIndex = this.licenseClassProducts.findIndex((lcp: ILicenseClassProduct) => (lcp.licenseClass = $event));
    if (licenseClassProductIndex > -1) {
      this.licenseClassProducts.splice(licenseClassProductIndex, 1);
    }
  }

  private removeLicenseClassProduct(licenseClassProduct: ILicenseClassProduct): void {
    if (licenseClassProduct.licenseClass) {
      this.removeLicenseClass(licenseClassProduct.licenseClass);
      removeObject(this.licenseClassProducts, licenseClassProduct);
    }
  }

  private removeLicenseClass(licenseClass: string) {
    const licenseClassIndex = this.licenseClasses.findIndex((lc: string) => lc === licenseClass);
    if (licenseClassIndex > -1) {
      this.licenseClasses.splice(licenseClassIndex, 1);
    }
  }

  public mounted(): void {
    this.filterLicenseClassesAction({ resource: "licence-classe" });
    this.findAllVatAction({ resource: "vat-options" });
    this.initData(this.product);
  }
}
