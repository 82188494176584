





































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import FscInput from "@/components/Field/FscInput.vue";
import { namespace } from "vuex-class";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import Table from "@/components/Table.vue";
import FscTable from "@/components/Table/FscTable.vue";
import { ILicenseClassProduct } from "@/interfaces/ILicenseClassProduct";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IProduct } from "@/interfaces/IProduct";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";

const SnackbarModule = namespace("snackbar");
//const LicenseClassModule = namespace("license-class");
const StandardProductModule = namespace("standard-product");
const VatModule = namespace("vat");

@Component({
  components: {
    SaveButton,
    AbortButton,
    RemoveButton,
    FscTable,
    Table,
    Validation,
    FscInput,
    FscCard,
  },
})
export default class ProductForm extends mixins(CompanyMixin) {
  public name = "ProductForm";

  private licenseClassProductsFields = [
    {
      key: "licenseClass",
      label: this.$tc("general.license_class"),
      sortable: false,
    },
    {
      key: "vat",
      label: this.$t("general.vat"),
      sortable: false,
    },
    {
      key: "bookAtStudentRegistration",
      label: this.$t("general.book_goods"),
      sortable: false,
    },
    { key: "actions", label: "" },
  ];

  @Validate({ required })
  public productName = "";

  /*@Validate({ required })
  public licenseClasses: Array<string> = [];*/

  private addonProduct = {
    id: -1,
    name: "Add on",
    standardProduct: true,
  }; // Addon

  public standardProduct: IProduct | null = null;

  public licenseClassProducts: Array<ILicenseClassProduct> = [];

  /*@LicenseClassModule.Action("findAll")
  public findAllLicenseClassesAction: any;*/

  @VatModule.Action("findAll")
  public findAllVatAction: any;

  /*@LicenseClassModule.Getter("getDataList")
  public licenseClassOptions: any;*/

  @VatModule.Getter("getDataList")
  public vats: any;

  @SnackbarModule.Action("setMessage")
  public errorAction: any;

  @StandardProductModule.Action("findAll")
  public findAllStandardProductsAction: any;

  @StandardProductModule.Getter("getDataList")
  private products: any;

  @Prop()
  public product!: IProduct;

  @Prop({ default: () => false, type: Boolean })
  public loading!: boolean;

  @Watch("product")
  public initData(product: IProduct): void {
    if (product) {
      this.productName = product.name ? product.name : "";
      this.licenseClassProducts = product.licenseClassProducts ? product.licenseClassProducts : [];
      this.standardProduct = product.standardProduct ? product.standardProduct : null;
      if (product.addon) {
        this.standardProduct = Object.assign(this.addonProduct);
      }
    }
    /*if (product.licenseClassProducts && this.licenseClassOptions) {
      this.licenseClasses = product.licenseClassProducts.map(
        (lc: any) => lc.licenseClass
      );
    }*/
  }

  get standardProducts() {
    if (this.products) {
      this.products.unshift(Object.assign(this.addonProduct));
      return this.products;
    }
    return [];
  }

  /*private licenseClassesSelectHandler($event: any): void {
    const licenseClassIndex = this.licenseClassProducts.findIndex(
      (lc: any) => lc.licenseClass === $event
    );
    if (licenseClassIndex === -1) {
      const vat = this.vats.length ? this.vats[0] : {};
      const newLicenseClass: ILicenseClassProduct = {
        productId: this.product && this.product.id ? this.product.id : 0,
        licenseClass: $event,
        bookAtStudentRegistration: false,
        vat: vat,
      };
      this.licenseClassProducts.push(newLicenseClass);
    }
  }*/

  /*private licenseClassesRemoveHandler($event: any): void {
    const licenseClassProductIndex = this.licenseClassProducts.findIndex(
      (lcp: ILicenseClassProduct) => (lcp.licenseClass = $event)
    );
    if (licenseClassProductIndex > -1) {
      this.licenseClassProducts.splice(licenseClassProductIndex, 1);
    }
  }*/

  /*private removeLicenseClassProduct(
    licenseClassProduct: ILicenseClassProduct
  ): void {
    if (licenseClassProduct.licenseClass) {
      this.removeLicenseClass(licenseClassProduct.licenseClass);
      removeObjectByProp(
        this.licenseClassProducts,
        licenseClassProduct,
        "licenseClass"
      );
    }
  }*/

  /*private removeLicenseClass(licenseClass: string) {
    const licenseClassIndex = this.licenseClasses.findIndex(
      (lc: string) => lc === licenseClass
    );
    if (licenseClassIndex > -1) {
      this.licenseClasses.splice(licenseClassIndex, 1);
    }
  }*/

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const addon = this.standardProduct !== null && this.standardProduct.id == -1;
    const product: IProduct = {
      name: this.productName,
      licenseClassProducts: this.licenseClassProducts,
      addon: addon,
    };
    if (!addon && this.standardProduct !== null) {
      product.standardProduct = this.standardProduct;
    }
    this.$emit("on-submit", product);
  }

  /*public isChecked(props: any): boolean {
    return !!this.licenseClasses.find((x: any) => x == props);
  }*/

  public mounted(): void {
    //this.findAllLicenseClassesAction({ resource: "license-classes" });
    this.findAllVatAction({ resource: "vat-options" });
    this.findAllStandardProductsAction({ resource: "standard-products" });
  }
}
