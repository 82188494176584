










































import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";

@Component({
  components: { FilterButton, AbortButton, FscModal },
})
export default class ProductsFilterModal extends Vue {
  public name = "ProductsFilterModal";

  @Prop({ type: String })
  public modalId!: string;

  @Prop({ type: Array, default: () => [] })
  public standardProducts!: Array<IBasicNamedDTO>;

  protected archive: boolean | null = false;
  protected standardProductIds: Array<number> | null = null;

  public onClearFilter(cancel: () => void): void {
    this.archive = false;
    this.standardProductIds = null;
    this.$emit("on-clear-filter", {
      archive: this.archive,
      standardProductIds: null,
    });
    this.$emit("filterActive", false);
    cancel();
  }

  public onFilter(ok: () => void): void {
    this.$emit("on-filter", {
      archive: this.archive,
      standardProductIds: this.standardProductIds,
    });
    this.$emit("filterActive", true);
    ok();
  }

  protected get archiveOptions(): Array<any> {
    return [
      {
        label: this.$t("todo.all"),
        value: null,
      },
      {
        label: this.$t("general.only_active"),
        value: false,
      },
      {
        label: this.$t("general.only_archived"),
        value: true,
      },
    ];
  }

  protected onInput(value: boolean | Array<any>) {
    if (Array.isArray(value)) {
      this.archive = null;
    } else {
      this.archive = value;
    }
  }
}
